




















































import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Vue from 'vue';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import MenuModel from '@/models/MenuModel';
import Exhibitor from '@/models/graphql/Exhibitor';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

const exhibitorStore = namespace('ExhibitorStore');

@Component({
  components: { ButtonIconComponent, FontAwesomeComponent, ButtonComponent },
})

export default class PackageUpgrade extends Vue {
  @Getter
  protected readonly community!: Community;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @Prop({ required: true, default: null })
  private readonly currentActiveMenu!: MenuModel;

  @Prop({ required: true, default: [] })
  private readonly availableSalesPackages!: SalesPackage[];

  @Prop({ required: false, default: true })
  private readonly isLoading!: boolean;

  private get availablePackage(): SalesPackage | undefined {
    if (this.availableSalesPackages && this.availableSalesPackages.length > 0) {
      return this.availableSalesPackages.find((availablePackage: SalesPackage) => !availablePackage.includedByDefault);
    }
    return undefined;
  }

  private onUpgradeButtonClick(): void {
    this.$router.push({ name: 'exhibitor-portal-upgrades' });
  }
}
