import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

/**
 * In Order for sticky mode to work you need 2 things:
 *    1- wrap your element(the element you wanted to be sticky)
 * in a div and should be also the root of your component.
 *    2- you need to set your stickyElementId in the created method of your component
 */

@Component
export default class VueStickyWidget extends VueBaseWidget {
  protected stickyElementId = '';

  protected stickyElementWrapperId = '';

  protected isItSticky = false;

  protected stickyOnBottom = false;

  protected top = 0;

  protected lastScrollPosition = 0;

  protected tabMenuHeader: HTMLElement | null = null;

  protected stickyElement: HTMLElement | null = null;

  protected wrapper: HTMLElement | null = null;

  private placeholderElement: HTMLElement | null = null;

  created(): void {
    window.addEventListener('scroll', this.makeTabMenuSticky);
  }

  mounted(): void {
    this.makeTabMenuSticky();
    this.placeholderElement = document.createElement('div');
    this.placeholderElement.setAttribute('id', `sticky-placeholder-${this.widget.id}`);
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.makeTabMenuSticky);
    if (this.tabMenuHeader) {
      const stickyHeaderElement = document.getElementById(this.stickyElementId);
      if (stickyHeaderElement) {
        stickyHeaderElement.remove();
      }
    }
  }

  private makeTabMenuSticky(): void {
    this.$nextTick(() => {
      this.wrapper = document.getElementById(this.stickyElementWrapperId) as HTMLElement;
      this.stickyElement = document.getElementById(this.stickyElementId) as HTMLElement;
      this.tabMenuHeader = document.querySelector('#app > .tab-menu-header') as HTMLElement;
      this.lastScrollPosition = window.scrollY;
      if (this.stickyElement && this.tabMenuHeader && this.placeholderElement) {
        const stickyPosition = this.stickyOnBottom
          ? this.wrapper.getBoundingClientRect().bottom
          : this.wrapper.getBoundingClientRect().top;
        if (stickyPosition < this.tabMenuHeader.getBoundingClientRect().height) {
          this.isItSticky = true;
          // eslint-disable-next-line no-unused-expressions
          document.querySelector('#app > .main-header')?.classList.remove('shadows-md');
          this.placeholderElement.setAttribute('style',
            `height: ${this.stickyElement.getBoundingClientRect().height}px;`);
          this.tabMenuHeader.appendChild(this.stickyElement);
          this.wrapper.insertBefore(this.placeholderElement, this.wrapper.firstChild);
        } else {
          this.isItSticky = false;
          if (window.scrollY >= 2) {
            // eslint-disable-next-line no-unused-expressions
            document.querySelector('#app > .main-header')?.classList.add('shadows-md');
          }
          if (this.stickyElement && this.wrapper && this.placeholderElement) {
            this.placeholderElement.remove();
            this.wrapper.insertBefore(this.stickyElement, this.wrapper.firstChild);
          }
        }
        const activeMenuParentEl = this.stickyElement.querySelector('div.tab-menu');
        if (activeMenuParentEl) {
          const activeMenuEl = activeMenuParentEl.querySelector('a.tab-menu-item.router-link-exact-active');
          if (activeMenuEl) {
            if (!(activeMenuEl.getBoundingClientRect().left >= 0
              && activeMenuEl.getBoundingClientRect().left <= activeMenuParentEl.getBoundingClientRect().right)) {
              activeMenuEl.scrollIntoView({
                block: 'nearest',
                inline: 'center',
              });
            }
          }
        }
      }
    });
  }
}
