
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import UiPage from '@/models/graphql/UiPage';
import { Data } from '@/utils/types/WidgetData';
import components from '@/utils/widgets/WidgetComponents';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';

const pageStateManagementStore = namespace('PageStateManagementStore');

@Component({
  name: 'LoadWidgetComponent',
})
export default class LoadWidgetComponent extends Vue {
  @State('pages')
  rootPages!: UiPage[];

  @pageStateManagementStore.State
  private pageReadyToDisplay!: number[];

  @Prop({ required: true })
  private readonly widget!: UiPageWidget;

  private component: object | string = 'div';

  private isAsyncComponentLoading = true;

  private get isLoadableWidget(): boolean {
    const { code } = this.payload;
    return !!(code && Object.keys(components).includes(code as string));
  }

  private get payload(): Data {
    return JSON.parse(this.widget.payload || '{}');
  }

  private get widgetProps(): Data {
    if (this.widget
      && this.widget.page
      && this.widget.page.id
      && this.pageReadyToDisplay.includes(this.widget.page.id)) {
      const storeName = WidgetHelper.widgetStoreFullPath(
        'WidgetDispatcherStore',
        this.widget.type,
        `${this.widget.id}${this.widget.page?.id}`,
      );
      if (this.widget.type === 'destination_advertise_panel'
        && this.widget.parentWidget
        && this.widget.parentWidget.type === 'destination_entity_search_panel') {
        return {
          ...this.$store.getters[`${storeName}/props`],
          isVisibleByDefault: false,
        };
      }
      return this.$store.getters[`${storeName}/props`];
    }
    return {};
  }

  hideContainer(containerWidget: UiPageWidget): boolean {
    if (containerWidget && containerWidget.subWidgets) {
      return this.hasNoSubWidgets(containerWidget)
        || (containerWidget.subWidgets.filter((w) => w.type !== 'destination_row').length === 0
          && containerWidget.subWidgets.filter((w) => w.type === 'destination_row' && !this.hideRow(w)).length === 0);
    }
    return true;
  }

  hideRow(rowWidget: UiPageWidget): boolean {
    if (rowWidget && rowWidget.subWidgets) {
      return this.hasNoSubWidgets(rowWidget)
        || (rowWidget.subWidgets.filter((w) => w.type !== 'destination_column').length === 0
          && rowWidget.subWidgets.filter((w) => w.type === 'destination_column' && !this.hideColumn(w)).length === 0);
    }
    return true;
  }

  hideColumn(columnWidget: UiPageWidget): boolean {
    return this.hasNoSubWidgets(columnWidget);
  }

  // eslint-disable-next-line class-methods-use-this
  hasNoSubWidgets(widget: UiPageWidget): boolean {
    if (widget.subWidgets) {
      return widget.subWidgets.length === 0;
    }
    return true;
  }

  private created(): void {
    const { code } = this.payload;
    if (code
      && Object.keys(components).includes(code as string)) {
      const path = components[code as string];
      import(`@/components/${path}`)
        .then((c: { default: object }) => {
          this.component = c.default;
        }).finally(() => {
          this.isAsyncComponentLoading = false;
        });
    }
  }
}
