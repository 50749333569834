






























import { Component, Prop } from 'vue-property-decorator';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { formatDistanceToNow } from 'date-fns';
import Session from '@/models/graphql/Session';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { State } from 'vuex-class';
import VueStickyWidget from '@/utils/widgets/VueStickyWidget';

@Component({
  components: { FontAwesomeComponent },
})
export default class LiveSessionAlertComponent extends VueStickyWidget {
  @Prop({ required: false, default: null })
  private liveSession!: Session | null;

  @State
  private dateLocale!: Locale;

  private displayedRemainingTime = '';

  private showWidget = true;

  created(): void {
    this.stickyElementId = 'live-session-alert';
    this.stickyElementWrapperId = 'live-session-alert-wrapper';
    this.refreshRemainingTime();
    setInterval(() => {
      this.refreshRemainingTime();
      if (this.displayedRemainingTime[0] === 'l') {
        this.showWidget = false;
      }
    }, 1000 * 60);
  }

  refreshRemainingTime(): void {
    if (this.liveSession && this.liveSession.startTime) {
      const startTime = DateTimeHelper.toLocal(new Date(this.liveSession.startTime));
      this.displayedRemainingTime = formatDistanceToNow(startTime, { addSuffix: true, locale: this.dateLocale });
    }
  }
}
